import React from 'react'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from "../lib/image-url";

const FocusPoint = ({FocusItem}) => {
    const {img, heading, bulletPoints} = FocusItem
    return (
        <div className="swiper-slide h-full focus relative flex items-center justify-start flex-col">
            <div className=" flex flex-col relative items-center  rounded-full">
                <div className="text-center number absolute dark-green z-10 h-10 w-10 font-bold flex justify-center items-center border-4 orange rounded-full lg:hidden">

                    <h2 className="text-center  text-lg orange font-bold">{FocusItem.number}</h2>
                </div>
                 <img className=" h-40 w-40 m-4 mb-10 p-2 object-contain rounded-full lg:rounded-none border-4 orange-border lg:border-none" src={imageUrlFor(buildImageObj(FocusItem.image))} />
            </div>
            <div className={`w-full h-auto lg:h-16 flex justify-center focus`}>
                <div className="w-12 h-auto lg:h-12 number hidden lg:flex items-center justify-center dark-green border orange rounded-full">

                    <h2 className="text-center text-lg orange font-bold">{FocusItem.number}</h2>
                </div>
            </div>
            <div className="flex pb-4 lg:h-24 font-bold text-center break-normal w-auto lg:w-full md:mt-6 text-xl font-bold leading-tight border-b-4 justify-center items-center">

                <h2 >{FocusItem.title}</h2>
            </div >
            <div className="text-left w-full">
                {FocusItem.bulletPoints.map((point) => {
                    return <p className="mt-5 h-30 text-sm w-full">{point}</p>
                })
            }
            </div>
        </div>
    )

}

export default FocusPoint