import React, {useState} from 'react'
import { graphql, StaticQuery } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import { ArrowLeft, ArrowRight } from "react-feather";
import Swiper from "react-id-swiper";
import SwiperSlide from "react-id-swiper";

import FocusPoint from './FocusPoint'

import { mapEdgesToNodes, filterOutDocsWithoutSlugs, buildImageObj } from '../lib/helpers'

const arrowButtonClass = "orange arrowClass h-10";

const FocusSection = (query) => {
    const [swiper, setSwiper] = useState(null);
    const { data } = query
    const focuspointNodes =
      data && data.Focuspoint && mapEdgesToNodes(data.Focuspoint).filter(filterOutDocsWithoutSlugs)

    const params4 = {
        rebuildOnUpdate: false,
        slidesPerView: 1,
        spaceBetween: 32,
        breakpoints: {
          1025: {
            slidesPerView: 5,
            spaceBetween: 64,
          },
        },
        slideClass: "swiper-slide",
        getSwiper: setSwiper,
        autoplay: true,
      };


    const goNext4 = () => {
        if (swiper !== null) {
          swiper.slideNext();
        }
      };
    
      const goPrev4 = () => {
        if (swiper !== null) {
          swiper.slidePrev();
        }
      };

    return (
      <StaticQuery
    query={graphql`
    {
      allSanityFocuspoint(sort: {fields: number}) {
        nodes {
          title
          number
          bulletPoints
          image {
            _key
            asset {
              _id
            }
          }
        }
      }
    }
    `}
    render={data => {
      const focuspointNodes = data && data.allSanityFocuspoint && mapEdgesToNodes(data.allSanityFocuspoint)
      console.log(data.allSanityFocuspoint.nodes)
      return (

    <div className="flex w-full justify-center relative px-10 lg:px-20 pb-10 lg:pb-20">

              <div className="h-10 w-1/12 mr-2 mt-20 border-0 link flex md:hidden">
                <ArrowLeft className={arrowButtonClass} onClick={goPrev4} />
              </div>
            <div className="h-full flex justify-between w-10/12 md:w-full">

            <Swiper {...params4}>
                {data.allSanityFocuspoint.nodes.map((FocusItem, index) => {
                    return <FocusPoint FocusItem={FocusItem} key={index}/>
                })}

            </Swiper>
            </div>
              <div className="h-10 w-1/12 mr-2 mt-20 border-0 link flex md:hidden">
                <ArrowRight className={arrowButtonClass} onClick={goNext4} />
              </div>
        </div>
      )
    }}
  />
    )
}

export default FocusSection