import React from 'react'
import { Link } from 'gatsby'
import EllipsisText from 'react-ellipsis-text'

import { buildImageObj, getStoryUrl } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

const StoryBox = props => {
  const { className, story } = props
  return (
    <div className={`swiper-slide h-full flex flex-col text-left justify-between items-center card ${className ? className : ''}`}>
      {story.title || story.excerpt ? (
        <div className=" flex flex-col relative w-full">
          {story.title ? (
            <strong className="inline-block w-full h-16 mb-2 p-4">{story.title}</strong>
            ) : null}
            {story.mainImage ? (
              <img
                className=" h-56 border-b-4 border-t-4 object-cover w-full"
                src={imageUrlFor(buildImageObj(story.mainImage)).url()}
              />
            ) : null}
          {story.excerpt ? (
            <p className="inline-block w-full h-24 mb-4 p-4 ">
              <EllipsisText text={story.excerpt} length={48} />
            </p>
          ) : null}
          {story.slug && story.slug.current ? (
            <Link
              className="inline-block card relative text-center py-1 ph-3 font-semibold rounded-3xl p-4 m-4"
              to={getStoryUrl(story.slug.current)}
            >
              Read More
            </Link>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

export default StoryBox
